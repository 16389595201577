export enum IconsEnum {
  Beenhere = 'beenhere',
  DirectionsCar = 'directions-car',
  ExitToApp = 'exit-to-app',
  List = 'list',
  HowToReg = 'how-to-reg',
  Security = 'security',
  Wallet = 'wallet',
  Timer = 'timer',
  Remove = 'remove',
  Tick = 'tick',
  Lights = 'lights',
  Tooltip = 'tooltip',
  User = 'user',
  Car = 'car',
  PhoneBlue = 'phone-blue',
  TickBlue = 'tick-blue',
  Calendar = 'ico-calendar',
  WalletBlue = 'wallet-blue',
  SecurityGreen = 'security-green',
  ArrowRight = 'outline-arrow-forward',
  Google = 'google',
  MubiMail = 'mubi-mail',
  Arrow = 'arrow',
  Starface = 'starface',
  ShieldCheck = 'shield-check',
  PhoneOutlined = 'phone-outlined',
  LockRounded = 'lock-rounded',
  ExclamationRounded = 'exclamation-rounded',
  TickRounded = 'tick-rounded',
  ChevronRight = 'chevron-right',
  Check = 'check',
  TransportCar = 'transport-car',
}
