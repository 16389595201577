import { RootState } from 'app/store/rootReducer';
import { PrivilegeEnum } from 'app/shared/enums/Privilege.enum';
import { RouteEnum } from 'app/shared/enums';

export const allowedToAddLeads = (state: RootState): boolean =>
  state.user.user !== undefined &&
  state.user.user.privileges.some(
    (privilege) => privilege === PrivilegeEnum.LeadsAdd
  );

export const selectAllowedToSaveOffer = (state: RootState): boolean =>
  state.user.user !== undefined &&
  state.user.user.privileges.some(
    (privilege) => privilege === PrivilegeEnum.OfferSave
  );

export const selectHasRequiredConsents = (state: RootState): boolean => {
  const { pathname } = window.location;
  const shouldConsentBeGranted =
    (state?.quotation?.id || state?.travelQuotation?.id) &&
    ([
      RouteEnum.CALCULATION_RESULTS,
      RouteEnum.TRAVEL_CALCULATION_RESULTS,
    ].includes(pathname as RouteEnum) ||
      pathname.includes(`${RouteEnum.HOME}/calculation/checkout`) ||
      pathname.includes(`${RouteEnum.TRAVEL}/travel-form-checkout`) ||
      pathname.includes(`${RouteEnum.TRAVEL}/travel-form-payment`));
  const isTravel = pathname.includes(RouteEnum.TRAVEL);
  const hasConsentOnQuotation = isTravel
    ? state?.travelQuotation?.consents?.regulations?.consent
    : state?.quotation?.consents?.regulations?.consent;

  return (
    Boolean(
      state.user.user &&
        state.user.user.consents.some(
          ({ consentId, granted }) => consentId === 'regulations' && granted
        )
    ) ||
    hasConsentOnQuotation ||
    !(state.user.loggedIn || shouldConsentBeGranted)
  );
};

export const isCheckingAuth = (state: RootState): boolean =>
  !!state.user.checkingAuth;

export const selectUserConsents = (state: RootState) =>
  state.user.user?.consents;

export const getUploadStep = (state: RootState) => state.user.reachedUploadStep;

export const getQuotationStep = (state: RootState): number =>
  state.user.reachedQuotationStep;

export const getUserData = (state: RootState) => state.user.user;

export const isUserLoggedIn = (state: RootState) => state.user.loggedIn;

export const selectIsOperator = (state: RootState): boolean =>
  !!state.user.user?.operator;

export const selectUserState = (state: RootState) => ({
  loggedIn: state.user.loggedIn,
  loggingIn: state.user.loggingIn,
  userErrorKey: state.user.error?.errorKey,
});

export const selectCheckoutStep = (state: RootState): number =>
  state.user.reachedCheckoutStep;
