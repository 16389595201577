export enum AbTestEnum {
  MultivariateUpsale = 'multivariateUpsale',
  OCOfferWithUpgradeToAC = 'OCOfferWithUpgradeToAC',
  OfferBoxV6 = 'enhancedRankingDetailsForAcAss_v6',
  ShortCalculationPathCepik = 'shortCalculationPathCepik_v7',
  ThankYouPageA = 'thankYouPageA',
  ThankYouPageB = 'thankYouPageB',
  ThankYouPageC = 'thankYouPageC',
  TravelNewRecommendationEngine = 'new_reko_engine',
}
